/**
 * Blog-Teaser
 * -------------------------
 */

.ce-blog-teaser {
  flex: 0 0 auto;

  &__link {
    float: right;
    font-size: rem-calc(12);
    font-weight: 700;
    line-height: rem-calc(32);
    margin-left: rem-calc(10);
    text-transform: uppercase;
  }

  .headline {
    margin-bottom: rem-calc(20);
  }
}
