/**
 * Teaser circle
 * -------------------------
 */

.ce-teaser-circle {
  &__columns {
    @include make-row();
    margin-bottom: rem-calc(-60);
    position: relative;

    &:before {
      @include rem-size(1080);
      border: rem-calc(1) solid $black;
      border-radius: 50%;
      content: '';
      left: rem-calc(40);
      display: block;
      position: absolute;
      top: rem-calc(-106);
      z-index: -1;
    }
  }

  &__column {
    @include make-col-ready();

    &:first-child {
      order: 1;
    }
  }

  &__area > * {
    margin-bottom: rem-calc(60);
  }

  @include media-breakpoint-up(md) {
    &__columns {
      margin-bottom: rem-calc(-80);
    }

    &__area > * {
      margin-bottom: rem-calc(80);
    }
  }

  @include media-breakpoint-only(md) {
    &__column {
      @include make-row();
    }

    &__area {
      @include make-col-ready();
      @include make-col(12);

      &--first {
        @include make-col-offset(1);
        @include make-col(11);
      }

      &--second {
        @include make-col-offset(2);
        @include make-col(9);
      }

      &--third {
        @include make-col-offset(1);
        @include make-col(10);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__columns {
      align-items: center;
      margin-bottom: 0;

      &:before {
        left: calc(50% - #{rem-calc(500)});
        top: rem-calc(-200);
      }
    }

    &__column {
      @include make-col(5);
      @include make-col-offset(1);

      .ce-teaser-circle__area:last-child > *:last-child {
        margin-bottom: 0;
      }
    }

    &__area--second {
      max-width: percentage(10/12);
    }

    &--alternative {
      .ce-teaser-circle__columns:before {
        top: rem-calc(-80);
      }

      .ce-teaser-circle__column {
        &:first-child {
          @include make-col(7);
        }

        &:last-child {
          @include make-col(4);
          @include make-col-offset(0);
        }
      }

      .ce-teaser-circle__area--second {
        max-width: percentage(5/7);
      }
    }
  }
}
