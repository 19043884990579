/**
 * Menus
 * -------------------------
 */

.menu-container-toggle {
  @include rem-size(50);
  background: url('#{$image-base-uri}icon-menu.svg') no-repeat;
}

.main-menu {
  &__items {
    display: flex;
    gap: rem-calc(20);

    @at-root .header--dark &--level-1 > li > a {
      color: $white;
    }

    @at-root .header--inverted &--level-1 > li > a {
      color: $white;
    }
  }

  &__item {
    > a {
      @include tiny-text();
      text-transform: uppercase;
      padding-bottom: rem-calc(18);
      padding-top: rem-calc(18);
      position: relative;
      white-space: nowrap;

      &:after {
        @include animate(width);
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: rem-calc(2);
        background: $body-color;

        @at-root .header--dark & {
          background: $white;
        }

        @at-root .header--inverted & {
          background: $white;
        }
      }
    }

    &--open .sub-menu {
      z-index: 1;
    }

    > a:hover,
    > a:focus,
    &--active > a,
    &--open > a {
      &:after {
        width: 100%;
      }
    }
  }
}

.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: $white;
  color: $body-color;
  display: none;

  &__columns {
    display: flex;
  }

  &__items {
    width: 74%;
    padding: rem-calc(60) rem-calc(60) rem-calc(60) rem-calc(20);

    @include media-breakpoint-up(md) {
      padding-left: rem-calc(40);
    }

    @include media-breakpoint-up(lg) {
      padding-left: rem-calc(60);
    }

    @include media-breakpoint-up(xl) {
      padding-left: rem-calc(120);
    }
  }

  &__item-group {
    &:not(:last-child) {
      margin-bottom: rem-calc(40);
    }

    &-title {
      border-bottom: rem-calc(1) solid $black;
      font-size: rem-calc(12);
      line-height: rem-calc(14);
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: rem-calc(10);
      padding-bottom: rem-calc(10);
    }

    &-links {
      display: flex;
      flex-wrap: wrap;
    }

    &-link {
      width: percentage(1/3);
      padding: rem-calc(20) rem-calc(10) 0 0;

      a {
        display: flex;
        gap: rem-calc(10);
        color: $body-color !important;
      }

      &-icon {
        flex: 0 0 auto;
        margin-top: rem-calc(-5);

        img {
          width: rem-calc(32);
          height: auto;
        }
      }

      &-text {
        @include small-text();
      }

      &-title {
        font-weight: bold;
        display: block;
      }

      &-description {
        display: block;
      }
    }

    &-teaser {
      display: flex;
      align-items: center;
      gap: rem-calc(20);
      width: 100%;
      padding: rem-calc(20) rem-calc(40);
      margin-top: rem-calc(40);
      background: $light-gray;

      &-image {
        width: 35%;

        img {
          @include img-fluid();
        }
      }

      &-text {
        @include small-text();
        width: 35%;

        .button {
          margin-top: rem-calc(20);
        }
      }

      &-headline {
        font-weight: 700;
        text-transform: uppercase;
      }

      &-points ul.checklist li:not(:last-child) {
        margin-bottom: rem-calc(10);
      }
    }
  }

  &__teasers {
    background: $medium-gray;
    width: 36%;
    padding: rem-calc(60) rem-calc(20) rem-calc(60) rem-calc(60);

    @include media-breakpoint-up(md) {
      padding-right: rem-calc(40);
    }

    @include media-breakpoint-up(lg) {
      padding-right: rem-calc(60);
    }

    @include media-breakpoint-up(xl) {
      padding-right: rem-calc(120);
    }
  }

  &__teaser {
    &:not(:last-child) {
      margin-bottom: rem-calc(40);
    }

    &-overline {
      @extend .sub-menu__item-group-title;
      margin-bottom: rem-calc(20);
    }

    &-content {
      display: flex;
      gap: rem-calc(10);
    }

    &-image {
      flex: 0 0 auto;

      img {
        width: rem-calc(200);
        height: auto;
      }

      &--small {
        img {
          width: rem-calc(100);
        }
      }
    }

    &-text {
      @include small-text();
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .link {
        margin-top: rem-calc(10);
      }

      .button {
        margin-top: rem-calc(20);
      }
    }

    &-title {
      font-weight: 700;
    }

    .podcast-links,
    .social-links {
      &__items {
        gap: rem-calc(15);
      }
    }
  }
}

.footer-menu {
  @include copy-text();

  &__items {
    @include unstyled-list();
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: rem-calc(16);
    }

    a {
      color: $white;
    }

    a {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &--meta {
    @include tiny-text();
    text-transform: uppercase;

    .footer-menu__items {
      display: flex;
    }

    .footer-menu__item {
      margin: 0 rem-calc(20) 0 0;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: $dark-gray;
      }

      a {
        @include animate(color);

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &--meta .footer-menu__items {
      justify-content: center;
    }
  }
}

.menu-overlay {
  background: $white;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: rem-calc(480);
  z-index: 100;

  @include media-breakpoint-up(sm) {
    width: 90%
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    padding: rem-calc(33) rem-calc(20) rem-calc(14);
    position: relative;
    height: rem-calc(23);
  }

  &__logo {
    @include animate(transform);
    @include rem-size(170, 23);
    background: url('#{$image-base-uri}logo.svg') no-repeat center;
    background-size: contain;
    display: block;
    will-change: transform;

    &:hover,
    &:focus {
      transform: scale(1.05);
    }

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__close-button {
    @include rem-size(50);
    background: url('#{$image-base-uri}icon-close.svg') no-repeat center;
    position: absolute;
    right: rem-calc(20);
    top: rem-calc(20);
    filter: invert(100%);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: rem-calc(10);
    padding: rem-calc(10) rem-calc(20) rem-calc(20);
    border-top: rem-calc(1) solid $black;
  }

  &__meta-menu {
    li {
      display: inline;
      font-size: rem-calc(16);
      line-height: rem-calc(50);
      font-weight: bold;
      text-transform: uppercase;

      &:not(:last-child) {
        &::after {
          content: '|';
        }
      }
    }
  }

  &__language-switcher .language-switcher__item {
    font-size: rem-calc(16);
    line-height: rem-calc(50);
  }

  &__cta {
    width: 100%;
  }

  &__scroll-area {
    flex: 1 1 auto;
    overflow: auto;
  }

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;

    &--main {
      margin-top: rem-calc(20);

      &:not(:last-child) {
        margin-bottom: rem-calc(10);
      }
    }

    &--level-1 {
      > .menu-overlay__item > a {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &--level-2 {
      display: none;
      background: $light-gray;
    }
  }

  &__item {
    > a {
      display: flex;
      align-items: center;
      gap: rem-calc(10);
      line-height: rem-calc(50);
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 rem-calc(20);
      position: relative;
      white-space: nowrap;

      .menu-overlay__item-icon {
        flex: 0 0 auto;

        img {
          width: rem-calc(30);
          height: auto;
        }
      }
    }

    &--has-children {
      > a {
        padding-right: rem-calc(40);

        &:after {
          @include animate(transform);
          @include rem-size(10);
          content: '';
          position: absolute;
          right: rem-calc(22);
          top: rem-calc(22);
          display: inline-block;
          border-right: rem-calc(3) solid $black;
          border-top: rem-calc(3) solid $black;
          transform: rotate(135deg);
          transform-origin: 66.6666% 33.3333%;
        }
      }

      &.menu-overlay__item--open > a:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.language-switcher {
  @include tiny-text();

  &__item {
    display: inline;
    opacity: 0.5;

    &:not(:last-child) {
      &::after {
        content: '|';
        opacity: 1;
      }
    }

    &--current {
      opacity: 1;
    }

    @at-root .header--dark & a {
      color: $white;
    }

    @at-root .header--inverted & a {
      color: $white;
    }
  }
}
