/**
 * Gallery
 * -------------------------
 */

.ce-gallery {
  text-align: center;

  .headline {
    margin-bottom: rem-calc(20);
  }

  &__img-wrapper {
    height: 0;
    padding-top: percentage(3/4);
    position: relative;

    img {
      left: 50%;
      max-height: 100%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }

  &--white-background {
    background: $white;
  }

  @include media-breakpoint-up(md) {
    &__wrapper {
      @include make-row();
      justify-content: center;
    }

    &__inner {
      @include make-col-ready();
      flex: 0 0 auto;
      max-width: rem-calc(830);
      width: percentage(10/12);
    }
  }

  @include media-breakpoint-up(lg) {
    &__inner {
      width: 50%;
    }

    .headline {
      margin-bottom: rem-calc(40);
    }
  }
}
