/**
 * Podcast
 * -------------------------
 */

.ce-podcast {
  &__wrapper {
    margin: 0 auto;
    max-width: rem-calc(1050);
  }

  &__item {
    background: $white;
    padding: rem-calc(20);
  }

  &__cover {
    margin-bottom: rem-calc(30);
    position: relative;

    .button {
      bottom: rem-calc(-10);
      position: absolute;
      right: rem-calc(-10);
    }
  }

  &__text {
    > .headline {
      margin-bottom: rem-calc(10);
    }

    .click-to-enhance .rte {
      margin: rem-calc(20) 0 rem-calc(30);
    }
  }

  &__details {
    @include small-text();
    color: $dark-gray;
    display: flex;
    flex-wrap: wrap;
    margin: rem-calc(20) 0 rem-calc(30);

    span:not(:last-child) {
      margin-right: rem-calc(20);
    }
  }

  &__embed {
    iframe,
    [data-type="placeholder"] {
      margin-top: rem-calc(40);
    }
  }

  @include media-breakpoint-up(md) {
    &__item {
      padding: rem-calc(40);
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      align-items: flex-start;
      display: flex;
    }

    &__cover {
      flex: 0 0 auto;
      margin-bottom: 0;
      margin-right: rem-calc(40);
      width: rem-calc(300);
    }

    &__text {
      flex: 1 1 auto;
    }

    &--small {
      .ce-podcast__cover {
        width: rem-calc(200);
      }
    }
  }
}
