/**
 * Overlay
 * -------------------------
 */

.ce-overlay {
  background: $white;
  margin: rem-calc(6) auto;
  max-width: rem-calc(1150);
  padding: rem-calc(20) rem-calc(20);
  position: relative;

  &--confetti {
    background: $white url('#{$image-base-uri}bg-confetti.svg') center;
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    margin-top: rem-calc(20);
  }

  .headline {
    text-align: center;
  }

  &__subheadline {
    @include tiny-text();
    margin-top: rem-calc(11);
    text-align: center;
    text-transform: uppercase;
  }

  &__link {
    margin-top: rem-calc(20);
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    padding: rem-calc(30) rem-calc(40);

    &__content {
      @include make-row();
    }

    &__subheadline {
      margin-top: rem-calc(21);
    }

    &__text,
    &__image {
      @include make-col-ready();
      @include make-col(1, 2);
    }

    &__content,
    &__link {
      margin-top: rem-calc(70);
    }
  }

  @include media-breakpoint-up(lg) {
    padding: rem-calc(60) rem-calc(90);

    &__text {
      @include make-col(11, 24);
    }

    &__image {
      @include make-col(13, 24);
    }
  }
}
