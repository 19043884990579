/**
 * Team
 * -------------------------
 */

.ce-team {
  .container {
    max-width: rem-calc(1600);
  }

  &__content {
    margin: 0 auto rem-calc(40);
    max-width: rem-calc(785);
    text-align: center;

    .headline {
      margin-bottom: rem-calc(10);
    }
  }

  &__grid {
    margin-bottom: $grid-gutter-width * -1;
  }

  &__person,
  &__quote {
    margin-bottom: $grid-gutter-width;

    > *:not(:last-child) {
      margin-bottom: $grid-gutter-width;
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      margin-bottom: rem-calc(80);
    }

    &__grid {
      @include make-row();
    }

    &__person,
    &__quote {
      @include make-col-ready();
      @include make-col(6);
    }

    &__person {
      .vcard {
        height: 100%;
      }
    }

    &__quote {
      .quote {
        height: 100%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      margin-bottom: rem-calc(120);
    }

    &__person,
    &__quote {
      &:nth-child(10n+4),
      &:nth-child(20n+16) {
        @include make-col-offset(1);
      }

      &:nth-child(10n+9) {
        @include make-col-offset(2);
      }
    }

    &__person {
      @include make-col(4);
    }

    &__quote {
      @include make-col(3);
    }
  }
}
