/**
 * Typography
 * --------------------
 */

.headline {
  margin: 0;

  &--xl {
    @include headline(xl);
  }

  &--lg {
    @include headline(lg);
  }

  &--md {
    @include headline(md);
  }

  &--sm {
    @include headline(sm);
  }

  &--xs {
    @include headline(xs);
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

.overline {
  @include tiny-text();
  text-transform: uppercase;

  &--gray {
    color: $dark-gray;
  }
}

a.overline {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

@each $alignment in left center right justify {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}
