/**
 * Accordion
 * -------------------------
 */

.ce-accordion {
  &__content {
    margin: 0 auto rem-calc(40);
    max-width: rem-calc(785);
    text-align: center;

    .headline:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }

  &__panes {
    margin: 0 auto;
    max-width: rem-calc(1000);
  }

  &__pane {
    &:not(:last-child) {
      padding-bottom: rem-calc(20);
    }

    &-toggle {
      @include button-hover-effect(span);
      @include large-copy-text();
      background: $white;
      border: none;
      display: block;
      padding: rem-calc(15) rem-calc(92) rem-calc(17) rem-calc(20);
      position: relative;
      text-align: left;
      width: 100%;

      span {
        align-items: center;
        background: $black;
        display: flex;
        justify-content: center;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: rem-calc(66);

        &:after {
          @include rem-size(20);
          background: url('#{$image-base-uri}icon-plus-alt.svg') no-repeat center;
          content: '';
          display: block;
          position: relative;
        }
      }

      @for $i from 1 through length($hover-colors) {
        &--color-variant-#{$i} {
          &:hover,
          &:focus {
            span:before {
              background-color: nth($hover-colors, $i);
            }
          }
        }
      }
    }

    &-body {
      max-width: rem-calc(820);
      padding: rem-calc(20) rem-calc(20) 0;

      .container {
        padding: 0;
      }
    }

    &--open {
      .ce-accordion__pane-toggle span:after {
        background-image: url('#{$image-base-uri}icon-minus-alt.svg');
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      margin-bottom: rem-calc(80);
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      margin-bottom: rem-calc(120);
    }

    &__pane {
      &-toggle {
        padding: rem-calc(18) rem-calc(92) rem-calc(20) rem-calc(20);

        span {
          width: rem-calc(72);
        }
      }
    }
  }
}
