/**
 * Forms
 * -------------------------
 */

.form {
  @include make-row();

  &__control {
    @include make-col-ready();
    margin-bottom: rem-calc(20);

    &:not(.form__control--checkbox):not(.form__control--radio) {
      label {
        @include tiny-text();
        display: block;
        margin: 0 0 rem-calc(15);
        text-transform: uppercase;
      }

      input,
      textarea,
      select {
        @include copy-text();
        border: none;
        border-bottom: rem-calc(2) solid $black;
        border-radius: 0;
        display: block;
        padding: rem-calc(10) rem-calc(10) rem-calc(12);
        width: 100%;
        -webkit-appearance: none;

        &.has-error {
          border-bottom-color: $error;
        }

        &:focus {
          outline: none;
        }
      }

      textarea {
        height: rem-calc(150);
        resize: vertical;
      }

      select {
        background: $white url('#{$image-base-uri}icon-dropdown.svg') no-repeat right rem-calc(10) center;
        background-size: rem-calc(12) rem-calc(8);
        padding-right: rem-calc(32);
      }
    }

    &--checkbox,
    &--radio {
      label {
        @include copy-text();
        margin: 0;
        position: relative;

        > span {
          display: block;
          padding-left: rem-calc(30);

          &:before {
            @include animate(opacity);
            @include rem-size(22);
            border: rem-calc(2) solid $black;
            content: '';
            display: block;
            left: 0;
            opacity: 0.6;
            position: absolute;
            top: rem-calc(2);
          }

          &:after {
            @include animate(opacity);
            @include rem-size(12);
            background: $black;
            content: '';
            display: block;
            left: rem-calc(5);
            opacity: 0;
            position: absolute;
            top: rem-calc(7);
          }

          &.rte--tiny-text {
            &:before {
              top: 0;
            }

            &:after {
              top: rem-calc(5);
            }
          }
        }

        &:hover {
          span:before {
            opacity: 1;
          }
        }

        &:not(:last-child) {
          margin-right: rem-calc(20);
        }
      }

      input {
        @include rem-size(22);
        left: 0;
        position: absolute;
        top: rem-calc(2);
        opacity: 0;

        &:focus {
          + span {
            &:before {
              opacity: 1;
            }
          }
        }

        &:checked {
          + span {
            &:before,
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &--radio label > span {
      &:before,
      &:after {
        border-radius: 50%;
      }
    }

    @for $i from 1 through length($hover-colors) {
      &--color-variant-#{$i} {
        &:not(.form__control--checkbox):not(.form__control--radio) {
          input,
          textarea {
            &:focus {
              border-bottom-color: nth($hover-colors, $i);
            }
          }
        }
      }
    }
  }

  &__buttons {
    @include make-col-ready();
    @include make-col(6);
  }

  &__required-fields-notice {
    @include make-col-ready();
    @include copy-text();
    @include make-col(6);
    line-height: rem-calc(50);
    text-align: right;
  }

  @include media-breakpoint-up(md) {
    &__control {
      &:not(.form__control--checkbox):not(.form__control--radio) {
        input,
        textarea,
        select {
          padding: rem-calc(18) rem-calc(20) rem-calc(20);
        }

        textarea {
          height: rem-calc(200);
        }

        select {
          background-position: right rem-calc(20) center;
          padding-right: rem-calc(42);
        }
      }

      &--small {
        @include make-col-ready();
        @include make-col(1, 2);
      }
    }
  }

  &--inverted {
    .form__control--checkbox,
    .form__control--radio {
      label > span {
        &:before {
          border-color: $white;
        }

        &:after {
          background: $white;
        }
      }
    }
  }

  &__buttons,
  &__required-fields-notice {
    margin-top: rem-calc(20);
  }

  @include media-breakpoint-down(sm) {
    &--only-required-fields {
      .form__control:not(.form__control--required) {
        display: none;
      }
    }
  }
}

.button {
  @include animate(opacity);
  @include button-hover-effect();
  @include tiny-text();
  background: $black;
  border: none;
  color: $white;
  display: inline-block;
  padding: rem-calc(18) rem-calc(20);
  position: relative;
  text-transform: uppercase;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  span:not(.sr-only) {
    position: relative;
  }

  &:hover,
  &:focus,
  a:hover &,
  a:focus & {
    color: $white;
  }

  &--play {
    padding: 0;

    &:after {
      @include rem-size(60);
      background: url('#{$image-base-uri}icon-play.svg') no-repeat center;
      content: '';
      display: block;
      position: relative;
    }
  }

  &--prev,
  &--next,
  &--prev-alt,
  &--next-alt,
  &--prev-colored,
  &--next-colored {
    background: none;
    padding: 0;

    &:after {
      @include rem-size(40);
      background: url('#{$image-base-uri}bg-next.svg') no-repeat center;
      content: '';
      display: block;
      position: relative;
    }
  }

  &--prev-alt,
  &--next-alt {
    border: rem-calc(2) solid $dark-gray;

    &:before {
      bottom: rem-calc(-2);
      left: rem-calc(-2);
      right: rem-calc(40);
      top: rem-calc(-2);
    }

    &:after {
      @include rem-size(36);
      background-image: url('#{$image-base-uri}bg-next-alt.svg');
      background-size: rem-calc(14) rem-calc(14);
    }

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      &:before {
        right: rem-calc(-2);
      }
    }
  }

  &--prev-colored,
  &--next-colored {
    &:before {
      background: $white;
    }

    &:after {
      background-image: url('#{$image-base-uri}bg-next-colored.svg');
    }
  }


  &--prev,
  &--prev-alt,
  &--prev-colored {
    &:after {
      transform: rotate(180deg);
    }
  }

  &--inverted {
    background: $white;
    color: $black;

    &:hover,
    &:focus {
      color: $black;
    }
  }

  &--colored {
    background: nth($hover-colors, 6);
  }

  @each $color in $hover-colors {
    &--colored-#{index($hover-colors, $color)} {
      background: $color;
    }
  }

  &--border {
    background: $white;
    border: rem-calc(2) solid $black;
    color: $black;
    padding-bottom: rem-calc(16);
    padding-top: rem-calc(16);

    &:before {
      bottom: rem-calc(-2);
      left: rem-calc(-2);
      position: absolute;
      right: calc(100% + #{rem-calc(2)});
      top: rem-calc(-2);
    }

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      color: $black;

      &:before {
        right: rem-calc(-2);
      }
    }

    &.button--inverted {
      background: black;
      border-color: $white;
      color: $white;

      &:hover,
      &:focus,
      a:hover &,
      a:focus & {
        color: $white;
      }
    }
  }

  &--outline {
    background: none;
    border: rem-calc(2) solid $black;
    color: $black;
    padding-bottom: rem-calc(16);
    padding-top: rem-calc(16);

    &.button--inverted {
      border-color: $white;
      color: $white;

      &:hover,
      &:focus,
      a:hover &,
      a:focus & {
        color: $white;
      }
    }
  }

  &--arrow {
    @include rem-size(50);
    background: none;
    border: rem-calc(2) solid $dark-gray;
    padding: 0;

    &:before {
      bottom: rem-calc(-2);
      left: rem-calc(-2);
      right: rem-calc(50);
      top: rem-calc(-2);
    }

    &:after {
      background: url('#{$image-base-uri}bg-arrow-button.svg') no-repeat center;
      background-size: rem-calc(70) rem-calc(70);
      content: '';
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
    }

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      &:before {
        right: rem-calc(-2);
      }

      &:after {
        background-image: url('#{$image-base-uri}bg-arrow-button-light.svg');
      }
    }

    @include media-breakpoint-up(md) {
      @include rem-size(60);

      &:before {
        right: rem-calc(60);
      }
    }

    @include media-breakpoint-up(lg) {
      @include rem-size(70);

      &:before {
        right: rem-calc(70);
      }
    }
  }

  &--scroll-down {
    padding-left: rem-calc(36);
    padding-right: rem-calc(36);

    &:after {
      @include rem-size(36);
      background: url('#{$image-base-uri}icon-arrow-down.svg') no-repeat center/60%;
      border: rem-calc(2) solid #275bc8;
      content: '';
      display: block;
      position: absolute;
      right: rem-calc(-7);
      top: rem-calc(-7);
    }
  }

  &--block {
    display: block;
    width: 100%;
    text-align: center;
  }

  @for $i from 1 through length($hover-colors) {
    &--color-variant-#{$i} {
      &:not(.button--colored):not(.button--next-colored):not(.button--prev-colored):before {
        background: nth($hover-colors, $i);
      }

      &.button--colored:before {
        background: $black;
      }
    }
  }
}

.errors-list {
  @include copy-text();
  @include unstyled-list();
  color: $error;
  display: none;
  font-weight: bold;
  margin: rem-calc(10) 0 0;
  padding: 0 rem-calc(20);
  position: relative;
  z-index: 1;

  &.filled {
    display: block;
  }
}
