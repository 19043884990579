/**
 * Webinar-Teaser
 * -------------------------
 */

.ce-webinar-teaser {
  background: no-repeat center/cover;
  padding: rem-calc(20);

  &--white-font {
    color: $white;
  }

  &__content {
    position: relative;

    .headline {
      margin-bottom: rem-calc(10);
    }

    .button {
      margin-top: rem-calc(40);
    }
  }

  @include media-breakpoint-up(lg) {
    padding: rem-calc(40);
  }
}
