/**
 * Procedure slider
 * -------------------------
 */

.ce-fields-of-activity {
  .figure {
    figcaption {
      .button-replacement {
        @include small-text();
        font-weight: 700;
        display: inline-block;
        text-transform: uppercase;
      }
    }
  }

  &__overlay-toggle {
    @include animate(transform);
    @include rem-size(28);
    background: url('#{$image-base-uri}icon-plus.svg') no-repeat center/contain;
    display: block;

    &:not(.ce-fields-of-activity__overlay-toggle--open) {
      animation: 1.5s wiggle ease-in-out infinite alternate;
    }

    &--open {
      transform: rotate(45deg);
    }
  }

  &__overlay {
    @include animate(opacity);
    background: $white;
    bottom: rem-calc(38);
    box-shadow: rem-calc(10) rem-calc(10) rem-calc(80) 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    padding: rem-calc(20);
    position: absolute;
    z-index: 1;

    .headline {
      margin-bottom: rem-calc(10);
    }

    .link {
      display: inline-block;
      margin-top: rem-calc(16);
    }

    &:not(&--open) {
      pointer-events: none;
    }

    &--open {
      opacity: 1;
    }
  }

  &__mobile-version {
    .ce-fields-of-activity__slide {
      position: relative;
    }

    .ce-fields-of-activity__overlay-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5%;
    }

    .ce-fields-of-activity__overlay-toggle {
      margin: 0 auto;
    }

    .ce-fields-of-activity__overlay {
      bottom: rem-calc(43);
      left: 0;
      right: 0;
    }

    .figure figcaption {
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: calc(5% + #{rem-calc(38)});
    }

    .tns-ovh {
      overflow: visible;
    }
  }

  &__desktop-version {
    position: relative;

    .figure {
      figcaption {
        margin: 0;
        position: absolute;
        transform: translate(-50%, calc(-100% - #{rem-calc(10)}));

        .button,
        .button-replacement {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 20%;
            left: 10%;
            width: 20%;
            height: 20%;
          }
        }

        &:nth-of-type(1) {
          left: 13.7%;
          top: 41%;
        }

        &:nth-of-type(2) {
          left: 86.25%;
          top: 41%;
        }

        &:nth-of-type(3) {
          left: 13.7%;
          top: 97%;
        }

        &:nth-of-type(4) {
          left: 86.25%;
          top: 97%;
        }

        &:nth-of-type(5) {
          left: 50%;
          top: 70%;
        }
      }

      .ce-fields-of-activity__image-link {
        display: block;
        width: 25%;
        height: 35%;
        position: absolute;
        transform: translateX(-50%);

        &--foresight {
          left: 13.7%;
          top: 1%;
        }

        &--strategy {
          left: 86.25%;
          top: 1%;
        }

        &--innovation {
          left: 13.7%;
          top: 55%;
        }

        &--people {
          left: 86.25%;
          top: 55%;
        }

        &--future {
          left: 50%;
          top: 25%;
          height: 40%;
        }
      }
    }

    .ce-fields-of-activity__overlay {
      left: rem-calc(15);
      max-width: calc(100vw - #{rem-calc(40)});
      width: rem-calc(400);
    }

     .ce-fields-of-activity__overlay-toggle {
       &:hover,
       &:focus {
         background-image: url('#{$image-base-uri}icon-plus-hover.svg');
       }
     }

    .ce-fields-of-activity__overlay-wrapper {
      position: absolute;
      margin-left: rem-calc(-15);

      &--first {
        left: 13.7%;
        top: 41%;
      }

      &--second {
        left: 86.25%;
        top: 41%;

        .ce-fields-of-activity__overlay {
          left: auto;
          right: rem-calc(15);
        }
      }

      &--third {
        left: 13.7%;
        top: 97%;
      }

      &--fourth {
        left: 86.25%;
        top: 97%;

        .ce-fields-of-activity__overlay {
          left: auto;
          right: rem-calc(15);
        }
      }

      &--fifth {
        left: 50%;
        top: 66%;
      }
    }
  }

  &__link {
    margin-top: rem-calc(15);
    padding-left: rem-calc(55);
    padding-right: rem-calc(55);
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    &__desktop-version {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    &__overlay {
      padding: rem-calc(40);
    }

    &__overlay-toggle {
      @include rem-size(30);
    }

    &__mobile-version {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    &__desktop-version {
      .ce-fields-of-activity__overlay {
        width: rem-calc(500);
      }
    }
  }
}
