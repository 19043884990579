/**
 * Teaser
 * -------------------------
 */

.ce-teaser {
  align-items: center;
  background: $black;
  color: $white;
  display: flex;
  flex-wrap: wrap;

  .headline {
    margin: 0 0 rem-calc(10);
  }

  a:not(.button) {
    color: $white;
  }

  &__image {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;

    img {
      width: auto;
      height: auto;
      margin: 10% 5% 10% 0;
      max-height: 80%;
      max-width: 80%;
      position: relative;
    }

    &:before {
      background: nth($hover-colors, 6);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 35%;
    }
  }

  &__content {
    padding: rem-calc(20);
    width: 100%;
  }

  &__link {
    display: flex;
    flex-wrap: wrap;

    .button {
      margin-top: rem-calc(20);

      &:not(:last-child) {
        margin-right: rem-calc(20);
      }
    }
  }

  @for $i from 1 through length($hover-colors) {
    &--color-variant-#{$i} {
      .ce-teaser__image:before {
        background-color: nth($hover-colors, $i);
      }
    }
  }

  &--gray-background {
    background: $gray;
    color: $body-color;

    a:not(.button) {
      color: $body-color;
    }
  }

  &--transparent-background {
    background: none;
    color: $body-color;

    a:not(.button) {
      color: $body-color;
    }
  }

  @include media-breakpoint-up(md) {
    &__image {
      align-self: stretch;
      width: 50%;
    }

    &__content {
      max-width: rem-calc(700);
      width: 50%;
      padding-left: rem-calc(40);
      padding-right: rem-calc(40);
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      padding-left: rem-calc(80);
      padding-right: rem-calc(80);

      .headline {
        margin-bottom: rem-calc(20);
      }

      .rte {
        margin-bottom: rem-calc(40);
      }
    }
  }
}
