/**
 * Podcast-Teaser
 * -------------------------
 */

.ce-podcast-teaser {
  background: no-repeat center/cover;
  padding: rem-calc(20);

  &--white-font {
    color: $white;

    .ce-podcast-teaser__intro {
      -webkit-text-stroke-color: $white;
    }
  }

  &__content {
    position: relative;

    .headline {
      margin-bottom: rem-calc(10);
    }

    .button {
      margin-top: rem-calc(40);
    }
  }

  &__intro {
    color: transparent;
    font-size: rem-calc(56);
    font-weight: bold;
    line-height: rem-calc(62);
    margin-bottom: rem-calc(17);
    -webkit-text-stroke: rem-calc(1) black;
    text-transform: uppercase;
  }

  &__links {
    display: flex;
    margin-top: rem-calc(30);
  }

  &__link {
    @include animate(color background-color);
    @include rem-size(40);
    background: $black;
    color: $white;
    display: block;

    svg {
      @include img-fluid();
    }

    &:not(:last-child) {
      margin-right: rem-calc(12);
    }

    &:hover,
    &:focus {
      background: $white;
    }

    @each $platform, $color in $podcast-colors {
      &--#{$platform} {
        &:hover,
        &:focus {
          color: $color;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__links {
      bottom: 0;
      margin-top: 0;
      position: absolute;
      right: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: rem-calc(40);
  }
}
