/**
 * Zoho Form Embed
 * -------------------------
 */

.ce-zoho-form-embed {
  &__wrapper {
    margin: 0 auto;
    max-width: rem-calc(600);
  }
}
