/**
 * Mixins and functions
 * --------------------
 */


@function rem-calc($value) {
  @return $value / 16 * 1rem;
}

@mixin rem-size($width, $height: $width) {
  width: rem-calc($width);
  height: rem-calc($height);
}

@mixin animate($properties) {
  $value: ();
  @each $property in $properties {
    $value: append($value, $property 300ms, comma);
  }
  transition: $value
}

@mixin stretch-to-parent($position: absolute) {
  bottom: 0;
  left: 0;
  position: $position;
  right: 0;
  top: 0;
}

@mixin headline($size) {
  @if $size == "xs" {
    font-size: rem-calc(16) !important;
    font-weight: 700;
    line-height: rem-calc(26) !important;
  } @elseif $size == "sm" {
    font-size: rem-calc(20) !important;
    font-weight: 700;
    line-height: rem-calc(26) !important;

    @include media-breakpoint-up(md) {
      font-size: rem-calc(22) !important;
      line-height: rem-calc(32) !important;
    }
  } @else if $size == "md" {
    font-size: rem-calc(24) !important;
    font-weight: 400;
    line-height: rem-calc(30) !important;

    @include media-breakpoint-up(md) {
      font-size: rem-calc(32) !important;
      line-height: rem-calc(40) !important;
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(42) !important;
      line-height: rem-calc(56) !important;
    }
  } @else if $size == "lg" {
    font-size: rem-calc(32) !important;
    font-weight: 400;
    line-height: rem-calc(40) !important;

    @include media-breakpoint-up(md) {
      font-size: rem-calc(38) !important;
      line-height: rem-calc(48) !important;
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(54) !important;
      line-height: rem-calc(64) !important;
    }
  } @else if $size == "xl" {
    font-size: rem-calc(32) !important;
    font-weight: 400;
    line-height: rem-calc(40)!important;

    @include media-breakpoint-up(md) {
      font-size: rem-calc(44) !important;
      line-height: rem-calc(54) !important;
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(60) !important;
      line-height: rem-calc(70) !important;
    }
  }
}

@mixin copy-text() {
  font-size: rem-calc(16);
  line-height: rem-calc(26);
}

@mixin large-copy-text() {
  font-size: rem-calc(22);
  line-height: rem-calc(34);
}

@mixin small-text() {
  font-size: rem-calc(14);
  line-height: rem-calc(20);
}

@mixin tiny-text() {
  font-size: rem-calc(12);
  font-weight: 700;
  line-height: rem-calc(14);
}

@mixin unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin dropdown-list {
  @include unstyled-list();
  @include copy-text();
  box-shadow: rem-calc(8) rem-calc(8) rem-calc(14) 0 rgba(0, 0, 0, 0.2);
  min-width: rem-calc(140);

  a {
    @include animate(background);
    background: $white;
    display: block;
    padding: rem-calc(15) rem-calc(20);
    white-space: nowrap;

    &:hover,
    &:focus {
      background: $gray;
    }
  }
}

@mixin button-hover-effect($element: null) {
  @if $element {
    #{$element}:before {
      @include animate(right);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 100%;
      top: 0;
    }

    &:hover,
    &:focus {
      #{$element}:before {
        right: 0;
      }
    }
  } @else {
    &:before {
      @include animate(right);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 100%;
      top: 0;
    }

    &:hover,
    &:focus {
      &:before {
        right: 0;
      }
    }
  }
}
