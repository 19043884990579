/**
 * Speakers
 * -------------------------
 */

.ce-speakers {
  &__content {
    margin: 0 auto rem-calc(80);
    max-width: rem-calc(785);
    text-align: center;

    .headline {
      margin-bottom: rem-calc(10);
    }
  }

  &__speakers {
    margin-bottom: rem-calc(-40);
  }

  &__speaker {
    display: block;
    margin-bottom: rem-calc(40);

    &-wrapper {
      background: $white;
      height: 0;
      padding-top: percentage(9/16);
      position: relative;
    }

    &-background {
      @include animate(width);
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &-content {
      left: 0;
      padding: rem-calc(20) calc(45% + #{rem-calc(20)}) rem-calc(20) rem-calc(20);
      position: absolute;
      right: 0;
      top: 0;
    }

    &-name {
      @include headline(sm);
    }

    &-title {
      @include tiny-text();
      color: $dark-gray;
      margin-top: rem-calc(2);
      text-transform: uppercase;
    }

    &-image {
      bottom: 0;
      position: absolute;
      right: 0;
      width: 45%;
    }

    @for $i from 1 through length($hover-colors) {
      &--color-variant-#{$i} &-background {
        background: nth($hover-colors, $i);
      }
    }

    @at-root a.ce-speakers__speaker {
      .ce-speakers__speaker-wrapper {
        background: $white url('#{$image-base-uri}icon-arrow-right.svg') no-repeat left rem-calc(20) bottom rem-calc(20);
        background-size: rem-calc(28) rem-calc(28);
      }

      &:hover,
      &:focus {
        .ce-speakers__speaker-background {
          width: 40%;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      margin-bottom: rem-calc(100);
    }

    &__speakers {
      @include make-row();
    }

    &__speaker {
      @include make-col-ready();
      @include make-col(6);
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      margin-bottom: rem-calc(120);
    }

    &__speakers {
      margin-bottom: rem-calc(-60);
    }

    &__speaker {
      @include make-col(5);
      margin-bottom: rem-calc(60);

      &:nth-child(odd) {
        @include make-col-offset(1);
      }

      &-wrapper {
        @at-root a.ce-speakers__speaker & {
          background-position: left rem-calc(40) bottom rem-calc(40);
        }
      }

      &-content {
        padding: rem-calc(40) calc(45% + #{rem-calc(40)}) rem-calc(40) rem-calc(40);
      }
    }
  }
}
