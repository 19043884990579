/**
 * Header
 * -------------------------
 */

.header {
  @include animate(background);
  position: relative;
  z-index: 10;

  &__inner {
    padding-bottom: rem-calc(14);
    padding-top: rem-calc(33);
  }

  &__logo {
    @include animate(transform);
    @include rem-size(170, 23);
    background: url('#{$image-base-uri}logo.svg') no-repeat center;
    background-size: contain;
    display: block;
    will-change: transform;

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }

  &__link {
    @include tiny-text();
    text-transform: uppercase;
  }

  &__cta,
  &__link {
    white-space: nowrap;
  }

  &--dark {
    background: $black;
    color: $white;

    .header__link {
      color: $white;
    }

    .header__logo {
      filter: invert(100%);
    }
  }

  &--inverted {
    color: $white;

    .header__link {
      color: $white;
    }

    .header__logo {
      filter: invert(100%);
    }
  }

  @include media-breakpoint-down(sm) {
    &__cta,
    &__link {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    &__menu {
      display: flex;
      align-items: center;
      gap: rem-calc(20);
      position: absolute;
      right: rem-calc(90);
      top: rem-calc(20);

      .main-menu {
        display: none;
      }

      .language-switcher {
        display: none;
      }
    }

    &__menu-toggle {
      position: absolute;
      right: rem-calc(20);
      top: rem-calc(20);
    }
  }

  @include media-breakpoint-up(lg) {
    &__inner {
      display: flex;
      align-items: center;
      padding-bottom: rem-calc(20);
      padding-top: rem-calc(20);
    }

    &__menu {
      display: flex;
      gap: rem-calc(20);
      flex: 1 1 auto;
      align-items: center;
      margin-left: rem-calc(60);

      .main-menu--mobile {
        display: none;
      }

      .language-switcher {
        margin-left: auto;
      }
    }

    &__menu-toggle {
      display: none;
    }
  }
}
