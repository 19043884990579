/**
 * Text-Teaser
 * -------------------------
 */

.ce-text-teaser {
  .overline {
    margin-bottom: rem-calc(10);
  }

  .headline {
    margin-bottom: rem-calc(20);
  }

  .rte + .button {
    margin-top: rem-calc(20);
  }

  @include media-breakpoint-up(lg) {
    .rte + .button {
      margin-top: rem-calc(40);
    }
  }
}
