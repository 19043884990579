/**
 * Styling for RTE-editor
 * --------------------
 */

.rte {
  h3 {
    @include headline(sm);
    margin: rem-calc(40) 0 rem-calc(11);
  }

  p {
    @include copy-text();
    margin: 0 0 rem-calc(20);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul, ol {
    margin: 0 0 rem-calc(20);
    padding: 0 0 0 rem-calc(45);

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin: 0 0 rem-calc(10) 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &:before {
        @include rem-size(5);
        background: $body-color;
        content: '';
        display: block;
        left: rem-calc(-25);
        position: absolute;
        top: rem-calc(9);
      }
    }

    &.checklist {
      padding-left: 0;

      li {
        background: url('#{$image-base-uri}icon-check.svg') no-repeat 0 rem-calc(4);
        padding-left: rem-calc(26);

        &:not(:last-child) {
          margin-bottom: rem-calc(20);
        }

        &:before {
          display: none;
        }
      }
    }
  }

  img {
    @include img-fluid();
  }

  a {
    @include animate(color);
    font-weight: 700;
    text-decoration: underline;
  }

  .highlight {
    background: $white;
    padding: rem-calc(20);

    &:not(:last-child) {
      margin-bottom: rem-calc(20);
    }
  }

  .marked {
    background-color: $black;
    color: $white;
  }

  @for $i from 1 through length($hover-colors) {
    &--color-variant-#{$i} {
      a {
        &:hover,
        &:focus {
          color: nth($hover-colors, $i) !important;
        }
      }
    }
  }

  &--large-text {
    p {
      @include large-copy-text();
    }
  }

  &--tiny-text {
    p {
      font-size: rem-calc(12);
      line-height: rem-calc(22);
    }
  }
}

.click-to-enhance {
  &__content {
    padding-top: rem-calc(20);
  }

  &__toggle {
    @include tiny-text();
    background: none;
    border: none;
    padding: 0;
    text-transform: uppercase;

    span {
      align-items: center;
      display: inline-flex;
      line-height: rem-calc(20);
      position: relative;

      &:after {
        @include rem-size(10, 6);
        background: url('#{$image-base-uri}icon-chevron-up.svg') no-repeat center;
        content: '';
        display: inline-block;
        margin-left: rem-calc(6);
      }
    }

    &--button {
      @include button-hover-effect();
      background: $white;
      color: $black;
      display: block;
      margin-top: rem-calc(20);
      padding: rem-calc(6);
      position: relative;
      text-align: center;
      width: 100%;

      @each $color in $hover-colors {
        &--color-variant-#{index($hover-colors, $color)}:before {
          background: $color;
        }
      }
    }

    &--show {
      span:after {
        transform: rotate(180deg);
      }
    }
  }
}
