/**
 * Footer
 * -------------------------
 */

.footer {
  background: $black;
  color: $white;
  padding: rem-calc(60) 0;

  &__sections {
    @include make-row();
  }

  &__section {
    @include make-col-ready();
    margin-bottom: rem-calc(50);

    &--social {
      .footer__section-inner > div:not(:last-child) {
        margin-bottom: rem-calc(50);
      }
    }

    &--menu {
      @include make-col(6);
      min-height: rem-calc(131);
    }

    &--meta {
      margin-bottom: 0;
    }
  }

  &__title {
    @include tiny-text();
    color: $dark-gray;
    margin-bottom: rem-calc(20);
    text-transform: uppercase;
  }

  &__award {
    @include rem-size(47, 131);
    background: url('#{$image-base-uri}icon-award.svg') no-repeat;
    bottom: rem-calc(164);
    display: block;
    position: absolute;
    right: rem-calc(20);
  }

  @include media-breakpoint-up(md) {
    padding: rem-calc(80) 0 rem-calc(20);

    &__section {
      margin-bottom: rem-calc(80);

      &--social {
        @include make-col(12);
      }

      &--menu {
        @include make-col(3);
      }

      &--meta {
        @include make-col(7);
        align-self: center;
        margin-bottom: 0;
      }
    }

    &__award {
      bottom: 0;
      right: rem-calc(40);
    }
  }

  @include media-breakpoint-only(md) {
    &__section--social {
      .footer__section-inner {
        @include make-row();

        > div {
          @include make-col-ready();
          @include make-col(1, 2);

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: rem-calc(80) 0 rem-calc(40);

    &__section {
      &--social {
        @include make-col(4);
      }

      &--menu {
        @include make-col(2);
      }
    }

    &__award {
      bottom: rem-calc(110);
      right: rem-calc(60);
    }
  }

  @include media-breakpoint-up(xl) {
    &__section {
      &--social {
        @include make-col(3);
      }
    }

    &__award {
      right: rem-calc(120);
    }
  }
}

.back-to-top {
  a {
    @include tiny-text();
    background: $very-dark-gray;
    color: $white !important;
    display: block;
    line-height: rem-calc(50);
    text-align: center;
    text-transform: uppercase;

    span {
      padding: 0 rem-calc(14);
      position: relative;

      &:before,
      &:after {
        @include rem-size(14);
        background: url('#{$image-base-uri}icon-arrow-up.svg') no-repeat center;
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.social-links,
.podcast-links {
  &__items {
    display: flex;
    gap: rem-calc(20);
  }

  &__item {
    @include rem-size(30);
    flex: 0 0 auto;
  }

  &__link {
    @include animate(color background-color);
    @include rem-size(30);
    background: $black;
    background-size: rem-calc(30) rem-calc(30);
    color: $white;
    display: inline-block;

    svg {
      @include img-fluid();
    }

    &:hover,
    &:focus {
      background: $white;
    }

    @each $platform, $color in map-merge($social-colors, $podcast-colors) {
      &--#{$platform} {
        &:hover,
        &:focus {
          color: $color !important;
        }
      }
    }
  }

  &--inverted {
    .social-links__link,
    .podcast-links__link {
      background: $white;
      color: $black;
    }
  }
}
