/**
 * Procedure slider
 * -------------------------
 */

.ce-procedure-slider {
  .figure {
    figcaption {
      .button-replacement {
        @include tiny-text();
        display: inline-block;
        text-transform: uppercase;
      }
    }
  }

  &__overlay-toggle {
    @include animate(transform);
    @include rem-size(38);
    background: url('#{$image-base-uri}icon-plus.svg') no-repeat center/contain;
    display: block;

    &:hover,
    &:focus {
      background-image: url('#{$image-base-uri}icon-plus-hover.svg');
    }

    &--open {
      transform: rotate(45deg);
    }
  }

  &__overlay {
    @include animate(opacity);
    background: $white;
    bottom: rem-calc(38);
    box-shadow: rem-calc(10) rem-calc(10) rem-calc(80) 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    padding: rem-calc(20);
    position: absolute;
    z-index: 1;

    .headline {
      margin-bottom: rem-calc(10);
    }

    .button {
      margin-top: rem-calc(20);
    }

    &:not(&--open) {
      pointer-events: none;
    }

    &--open {
      opacity: 1;
    }
  }

  &__mobile-version {
    .ce-procedure-slider__overlay-wrapper {
      position: relative;
    }

    .ce-procedure-slider__overlay {
      bottom: rem-calc(43);
      left: 0;
      right: 0;
    }

    .ce-procedure-slider__overlay-toggle {
      margin: rem-calc(20) auto 0;
    }
  }

  &__desktop-version {
    position: relative;

    .figure {
      padding-bottom: rem-calc(120);

      figcaption {
        margin: 0;
        position: absolute;
        transform: translate(-50%, calc(-100% - #{rem-calc(20)}));

        &:nth-of-type(1) {
          left: 6%;
          top: 60%;
        }

        &:nth-of-type(2) {
          left: 23.75%;
          top: 95%;
        }

        &:nth-of-type(3) {
          left: 48%;
          top: 94.5%;
        }

        &:nth-of-type(4) {
          left: 70.4%;
          top: 74%;
        }

        &:nth-of-type(5) {
          left: 89.8%;
          top: 76%;
        }
      }
    }

    .ce-procedure-slider__overlay {
      left: rem-calc(38);
      max-width: calc(100vw - #{rem-calc(40)});
      width: rem-calc(400);
    }

    .ce-procedure-slider__overlay-wrapper {
      position: absolute;
      margin-left: rem-calc(-19);

      &--first {
        left: 6%;
        top: 60%;
      }

      &--second {
        left: 23.75%;
        top: 95%;
      }

      &--third {
        left: 48%;
        top: 94.5%;

        .ce-procedure-slider__overlay {
          left: auto;
          right: rem-calc(38);
        }
      }

      &--fourth {
        left: 70.4%;
        top: 74%;

        .ce-procedure-slider__overlay {
          left: auto;
          right: rem-calc(38);
        }
      }

      &--fifth {
        left: 89.8%;
        top: 76%;

        .ce-procedure-slider__overlay {
          left: auto;
          right: rem-calc(38);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__desktop-version {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    &__overlay {
      padding: rem-calc(40);
    }

    &__mobile-version {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    &__desktop-version {
      .ce-procedure-slider__overlay {
        width: rem-calc(500);
      }
    }
  }
}
