/**
 * Content-Teaser
 * -------------------------
 */

.ce-content-teaser {
  background: $white;
  flex: 0 0 auto;
  position: relative;

  &__image {
    img {
      @include img-fluid();
    }
  }

  &__content-inner {
    background: $white;
    padding: rem-calc(40) rem-calc(20);
    position: relative;

    .overline {
      margin-bottom: rem-calc(10);
    }

    .headline + .rte {
      margin-top: rem-calc(10);
    }

    .rte {
      padding-right: rem-calc(30);
    }

    .button {
      bottom: rem-calc(-10);
      position: absolute;
      right: rem-calc(-10);
    }
  }

  &__progress {
    bottom: rem-calc(-6);
    height: rem-calc(6);
    left: 0;
    position: absolute;
    width: 0;
    will-change: width;
    z-index: -1;

    @for $i from 1 through length($hover-colors) {
      &--color-variant-#{$i} {
        background-color: nth($hover-colors, $i);
      }
    }
  }

  &--quote {
    .ce-content-teaser__content-inner:before {
      @include rem-size(30, 20);
      background: url('#{$image-base-uri}icon-quote.svg') no-repeat center;
      background-size: contain;
      content: '';
      left: rem-calc(20);
      position: absolute;
      top: rem-calc(-5);
    }
  }

  &--large {
    &.ce-content-teaser--quote {
      .ce-content-teaser__content-inner {
        padding: rem-calc(20);

        &:before {
          left: rem-calc(10);
          top: rem-calc(-10);
        }
      }
    }

    &.ce-content-teaser--with-image,
    &.ce-content-teaser--slider {
      .ce-content-teaser__content {
        bottom: 0;
        left: rem-calc(20);
        max-width: rem-calc(600);
        overflow: hidden;
        padding: rem-calc(20) rem-calc(20) rem-calc(20) 0;
        position: absolute;
        width: calc(100% - #{rem-calc(20)});

        &-inner {
          padding: rem-calc(20);
        }

        &.aos-init {
          // Override styles inherited by [data-aos]
          transition: none;

          .ce-content-teaser__content-inner {
            animation: 300ms ease-in-out 250ms both slideOutLeft;

            &:before {
              animation: 200ms ease-in-out 100ms both fadeOut;
            }

            .overline,
            .headline,
            .rte {
              animation: 200ms ease-in-out 50ms both fadeOut;
            }

            .button {
              animation: 400ms ease-in-out both fadeOut;
            }
          }

          &.aos-animate {
            .ce-content-teaser__content-inner {
              animation: 300ms ease-in-out both slideInLeft;

              &:before {
                animation: 200ms ease-in-out 200ms both fadeIn;
              }

              .overline,
              .headline,
              .rte {
                animation: 200ms ease-in-out 250ms both fadeIn;
              }

              .button {
                animation: 400ms ease-in-out 300ms both fadeIn;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content-inner {
      padding: rem-calc(40);
    }

    &--quote {
      .ce-content-teaser__content-inner {
        padding: rem-calc(40);

        &:before {
          @include rem-size(40, 27);
        }
      }
    }

    &--large {
      &.ce-content-teaser--with-image,
      &.ce-content-teaser--slider {
        .ce-content-teaser__content {
          left: rem-calc(40);
          padding-bottom: rem-calc(40);
          padding-right: rem-calc(40);
          width: calc(100% - #{rem-calc(40)});

          &-inner {
            padding: rem-calc(40);
          }
        }
      }
    }
  }
}
