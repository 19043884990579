/**
 * Contact-Teaser
 * -------------------------
 */

.ce-contact-teaser {
  background: $black;
  color: $white;

  > a {
    display: block;
    color: inherit;
  }

  &__content {
    padding: rem-calc(80) 0;

    .headline {
      margin-bottom: rem-calc(20);
      max-width: rem-calc(1000);
    }

    .rte {
      max-width: rem-calc(800);

      + .button {
        margin-top: rem-calc(20);
      }
    }
  }

  &--with-image {
    .ce-contact-teaser__image {
      padding: rem-calc(20) 0 0;
    }
  }

  &--white-background {
    background: $white;
    color: $body-color;
  }

  @include media-breakpoint-down(sm) {
    &--with-image {
      .ce-contact-teaser__content {
        padding-top: rem-calc(20);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      padding-bottom: rem-calc(100);
      padding-top: rem-calc(100);
    }

    &--with-image {
      .ce-contact-teaser__inner {
        @include make-row();
      }

      .ce-contact-teaser__image {
        @include make-col-ready();
        @include make-col(4);
        @include make-col-offset(1);
        align-self: flex-start;
        margin-bottom: rem-calc(-40);
        padding-top: rem-calc(80);

        &--right {
          order: 1;
        }
      }

      .ce-contact-teaser__content {
        @include make-col-ready();
        @include make-col(5);
        @include make-col-offset(1);
        align-self: center;
      }
    }

    &--without-image {
      .ce-contact-teaser__content {
        text-align: center;

        .headline,
        .rte {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      padding-bottom: rem-calc(120);
      padding-top: rem-calc(120);

      .rte + .button {
        margin-top: rem-calc(40);
      }
    }
  }
}
