/**
 * Stage
 * -------------------------
 */

.ce-stage {
  &__slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;
  }

  &__background {
    @include stretch-to-parent();
    background: no-repeat center/cover;

    video {
      @include stretch-to-parent();
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &--darken:after {
      @include stretch-to-parent();
      background: radial-gradient(178.3% 116.75% at 49.39% 57.29%, #000 0%, rgba(0, 0, 0, 0.311817) 100%);
      content: '';
      display: block;
      opacity: 0.75;
    }
  }

  &__wrapper {
    height: 100%;
    padding: rem-calc(150) rem-calc(20) rem-calc(140);
    position: relative;
  }

  &__content {
    margin: 0 auto;
    max-width: rem-calc(895);

    .overline {
      margin-bottom: rem-calc(5);
    }

    .headline {
      margin-bottom: rem-calc(20);
    }

    .rte {
      margin: 0 auto;
      max-width: rem-calc(660);
    }

    &--white-font {
      color: $white;
    }
  }

  &__link {
    margin-top: rem-calc(30);

    &--glue-to-bottom {
      bottom: 0;
      left: 50%;
      margin: 0 !important;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  &:first-child {
    margin-top: rem-calc(-70);
  }

  @include media-breakpoint-up(md) {
    &__wrapper {
      padding-bottom: rem-calc(160);
      padding-top: rem-calc(170);
    }

    &__content .headline {
      margin-bottom: rem-calc(40);
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: rem-calc(700);

    &:first-child {
      margin-top: rem-calc(-90);
    }

    &__wrapper {
      padding-bottom: rem-calc(180);
      padding-top: rem-calc(210);
    }

    &__link {
      margin-top: rem-calc(40);
    }
  }
}
