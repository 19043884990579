/**
 * Contact-Form
 * -------------------------
 */

.ce-contact-form {
  .headline {
    margin: 0 0 rem-calc(10);
  }

  .figure {
    margin: rem-calc(10) 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &__thank-you {
    margin: 0 auto;
    max-width: rem-calc(640);
    text-align: center;

    .headline {
      background: url('#{$image-base-uri}bg-thank-you-dark.svg') no-repeat center top;
      padding-top: rem-calc(171);
    }
  }

  &--default {
    .ce-contact-form__content,
    .ce-contact-form__form {
      margin: 0 auto;
      max-width: rem-calc(780);
    }

    .ce-contact-form__content {
      text-align: center;
      margin-bottom: rem-calc(40);

      @at-root .ce-accordion & {
        text-align: left;
      }
    }

    .form__buttons {
      @include make-col(12);
    }

    .form__required-fields-notice {
      @include make-col(12);
      text-align: left;
      margin-top: 0;
    }
  }

  &--teaser-1,
  &--teaser-2 {
    background: $black;
    color: $white;

    a:not(.button) {
      color: $white;
    }

    .ce-contact-form__thank-you .headline {
      background-image: url('#{$image-base-uri}bg-thank-you-light.svg');
      padding-top: rem-calc(136);
    }
  }

  &--teaser-2 {
    .rte {
      margin-bottom: rem-calc(20);
    }

    .figure {
      margin: 0;
    }
  }

  @include media-breakpoint-down(md) {
    &--teaser-1,
    &--teaser-2 {
      .ce-contact-form__column {
        &:first-child {
          text-align: center;
          padding: rem-calc(20) 0 rem-calc(20);
        }

        &:last-child {
          padding: 0 0 rem-calc(20);
        }
      }

      .ce-contact-form__thank-you {
        padding: rem-calc(40) 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__thank-you {
      padding: rem-calc(80) 0;
    }

    &--default {
      .ce-contact-form__content {
        margin-bottom: rem-calc(80);
      }
    }

    &--teaser-1 {
      .ce-contact-form__wrapper {
        @include make-row();
      }

      .ce-contact-form__column {
        @include make-col-ready();
        @include make-col(5);
        padding-bottom: rem-calc(80);
        padding-top: rem-calc(80);

        &:first-child {
          @include make-col-offset(1);
        }
      }
    }

    &--teaser-2 {
      .rte {
        margin-bottom: rem-calc(40);
      }

      .figure {
        margin-bottom: rem-calc(-40);
      }

      .ce-contact-form__wrapper {
        @include make-row();
      }

      .ce-contact-form__column {
        @include make-col-ready();
        @include make-col-offset(1);
        padding-bottom: rem-calc(80);
        padding-top: rem-calc(80);

        &:first-child {
          @include make-col(4);
          padding-bottom: 0;
        }

        &:last-child {
          @include make-col(5);
          align-self: center;
        }
      }
    }
  }
}
