/**
 * Twitter-Teaser
 * -------------------------
 */

.ce-twitter-teaser {
  height: 100%;

  &--white-background {
    background: $white;
  }
}

.twitter-status {
  padding: rem-calc(20);

  &__meta {
    @include clearfix();
    background: url('#{$image-base-uri}bg-twitter.svg') no-repeat right top;
    background-size: rem-calc(25) rem-calc(21);
    margin-bottom: rem-calc(20);
  }

  &__avatar {
    @include rem-size(47);
    border: rem-calc(1) solid $gray;
    border-radius: 50%;
    float: left;
    margin: 0 rem-calc(10) 0 0;
    overflow: hidden;

    img {
      @include img-fluid();
    }
  }

  &__display-name {
    @include copy-text();
    font-weight: 700;
  }

  &__username {
    font-size: rem-calc(14);
    line-height: rem-calc(20);

    a {
      color: transparentize(#292B2D, 0.5);
    }
  }

  @include media-breakpoint-only(md) {
    &__text {
      padding: 0 rem-calc(20);
    }
  }

  @include media-breakpoint-up(lg) {
    padding: rem-calc(40);

    &__meta {
      margin-bottom: rem-calc(40);
    }
  }
}
