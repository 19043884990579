/**
 * Quotes
 * -------------------------
 */

.ce-quotes {
  &--white-background {
    background: $white;
  }

  &__overline {
    @include tiny-text();
    margin-bottom: rem-calc(10);
  }

  &__quote {
    @include headline(md);
  }

  &__source {
    @include tiny-text();
    margin-top: rem-calc(20);
  }

  @include media-breakpoint-down(sm) {
    &__text {
      display: flex;
      flex-wrap: wrap;
      padding-top: rem-calc(80);
    }

    &__overline {
      flex: 0 0 auto;
      width: 100%;
    }

    &__quote {
      flex: 0 0 auto;
      padding-bottom: rem-calc(80);
      width: 100%;
    }

    &__source {
      flex: 1 1 auto;
      margin-top: rem-calc(-60);
      padding-bottom: rem-calc(80);
    }

    &__image {
      flex: 0 0 auto;
      margin-left: rem-calc(10);
      margin-top: rem-calc(-60);
      padding-bottom: rem-calc(20);
      width: percentage(5 / 12);
    }

    .slider--active {
      padding-bottom: rem-calc(40);
    }
  }

  @include media-breakpoint-up(md) {
    &__wrapper {
      @include make-row();
    }

    &__inner {
      @include make-col-ready();
      @include make-col(10);
      @include make-col-offset(1);
    }

    &__item {
      align-items: center;
      display: flex;
      margin-right: -$grid-gutter-width / 2;
      margin-left: -$grid-gutter-width / 2;
    }

    &__text {
      flex: 1 1 auto;
      padding: rem-calc(100) $grid-gutter-width / 2;
      width: 100%;
    }

    &__image {
      flex: 0 0 auto;
      padding: rem-calc(20) $grid-gutter-width / 2;
      width: percentage(3 / 10);
    }
  }

  @include media-breakpoint-up(lg) {
    &__text {
      padding-bottom: rem-calc(120);
      padding-top: rem-calc(120);
    }

    &__image {
      padding-bottom: rem-calc(40);
      padding-top: rem-calc(40);
    }
  }
}
