/**
 * Page-Intro
 * -------------------------
 */

.ce-page-intro {
  @include clearfix();

  &__content {
    padding: rem-calc(80) 0;

    .headline {
      max-width: rem-calc(1000);
    }

    .rte {
      margin-top: rem-calc(10);
      max-width: rem-calc(800);
    }
  }

  &__overline {
    @include tiny-text();
    letter-spacing: rem-calc(1.5);
    margin-bottom: rem-calc(10);
    max-width: rem-calc(800);
    text-transform: uppercase;
  }

  &__link {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem-calc(10);

    .button {
      margin-top: rem-calc(20);

      &:not(:last-child) {
        margin-right: rem-calc(20);
      }
    }
  }

  &__image {
    padding: rem-calc(20) 0 rem-calc(80);
    text-align: center;

    figure {
      margin: 0 auto;
      max-width: rem-calc(1000);
    }

    img {
      height: auto;
      max-height: rem-calc(800);
      max-width: 100%;
      width: auto;
    }
  }

  &--with-image {
    .ce-page-intro__content {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    &__wrapper {
      @include make-row();
      align-items: center;
    }

    &__content {
      @include make-col-ready();
      padding-bottom: rem-calc(100);
      padding-top: rem-calc(100);
    }

    &__link {
      margin-top: rem-calc(20);
    }

    &--without-image {
      .ce-page-intro__content {
        @include make-col(10);
        @include make-col-offset(1);

        .ce-page-intro__overline,
        .headline,
        .rte,
        .ce-page-intro__link {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }
    }

    &--with-image {
      .ce-page-intro__content {
        @include make-col(6);
        padding-bottom: rem-calc(100);
      }

      .ce-page-intro__image {
        @include make-col-ready();
        @include make-col(6);
        padding-bottom: rem-calc(80);
        padding-top: rem-calc(80);
      }

      &.ce-page-intro--image-left .ce-page-intro__image {
        order: -1;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      padding-bottom: rem-calc(160);
      padding-top: rem-calc(160);
    }

    &--without-image {
      .ce-page-intro__content {
        @include make-col(6);
        @include make-col-offset(3);
      }
    }
  }
}
