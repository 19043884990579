/**
 * Logos
 * -------------------------
 */

.ce-logos {
  &__content {
    margin: 0 auto rem-calc(40);
    max-width: rem-calc(785);
    text-align: center;

    .headline:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }

  &__items {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &[data-count="1"],
    &[data-count="2"] {
      justify-content: center;
    }
  }

  &__item {
    flex: 0 0 auto;
    padding: rem-calc(10);
    width: percentage(1 / 3);

    figure {
      @include rem-size(260, 120);
      margin: 0 auto;
      max-width: 100%;
      position: relative;

      img {
        height: auto;
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &--white-background {
    background: $white;
  }

  @include media-breakpoint-up(md) {
    &__content {
      margin-bottom: rem-calc(60);
    }

    &__items {
      &[data-count="3"],
      &[data-count="4"] {
        justify-content: center;
      }
    }

    &__item {
      width: 20%;
    }
  }

  @include media-breakpoint-up(lg) {
    &__items {
      &[data-count="5"],
      &[data-count="6"],
      &[data-count="7"] {
        justify-content: center;
      }
    }

    &__item {
      padding: rem-calc(20);
      width: 12.5%;

      @at-root .ce-logos__items[data-count="12"] & {
        width: percentage(1/6);
      }
    }
  }
}
