/**
 * Facts-Teaser
 * -------------------------
 */

.ce-facts-teaser {
  background: no-repeat top left;

  &--black-background {
    background-color: $black;
    color: $white;

    .button {
      @extend .button--inverted;
    }
  }

  &--white-background {
    background-color: $white;
  }

  &--full-height {
    height: 100%;

    .slider {
      height: 100%;
    }
  }

  &--align-middle {
    .slider {
      .tns-ovh {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__item {
    padding: rem-calc(60) rem-calc(20);
    text-align: center;

    .overline {
      margin: rem-calc(-20) 0 rem-calc(20);
    }

    .figure {
      margin-bottom: rem-calc(20);

      img {
        height: rem-calc(80);
        width: auto;
      }
    }

    .headline + .rte {
      margin-top: rem-calc(10);
    }

    .button {
      margin-top: rem-calc(40);
    }

    &--left {
      text-align: left;
    }
  }

  .slider {
    display: flex;
    flex-direction: column;

    > :first-child {
      flex: 1 0 auto;
    }

    > :last-child {
      flex: 0 0 auto;
    }

    &__controls {
      margin-top: rem-calc(-21);
    }
  }

  @include media-breakpoint-up(md) {
    &__item {
      padding: rem-calc(60) rem-calc(40) rem-calc(80);
    }

    .slider__controls {
      margin-top: rem-calc(-41);
    }
  }
}
