/**
 * Klaro
 * --------------------
 */

@import "~klaro/src/scss/klaro";

.klaro {
  --font-size: #{rem-calc(16)};
  --border-radius: 0;
  --dark1: #{$black};
  --dark3: #{$dark-gray};
  --light3: #{$dark-gray};
  --green1: #{$success};
  --notice-max-width: #{rem-calc(500)};

  .cookie-modal,
  .context-notice,
  .cookie-notice {
    h1 {
      font-weight: 400;
    }

    a {
      color: $white;
      text-decoration: underline;
    }

    label {
      margin-bottom: rem-calc(4);
    }

    .cm-service label {
      margin-bottom: 0;
    }

    .cm-btn,
    .cm-btn.cm-btn-success,
    .cm-btn.cm-btn-success-var {
      background: $white;
      color: $black;

      &:hover,
      &:focus {
        background: nth($hover-colors, 10);
      }
    }

    .cm-list-label .slider{
      background: $dark-gray;
    }

    .cm-caret a {
      text-decoration: none;
    }
  }

  .cookie-notice:not(.cookie-modal-notice),
  .cookie-modal {
    z-index: 10000;
  }

  &.cm-as-context-notice {
    background: $black no-repeat center/cover;
    display: flex;
    position: relative;
    padding: 0;

    &:before {
      background: no-repeat center/contain;
      content: '';
      display: block;
      height: 33%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 33%;

      @at-root [data-name="podigee"] & {
        background-image: url('#{$image-base-uri}icon-podigee-play.webp');
      }

      @at-root [data-name="youTube"] & {
        background-image: url('#{$image-base-uri}icon-youtube-play.svg');
      }

      @at-root [data-name="vimeo"] & {
        background-image: url('#{$image-base-uri}icon-vimeo-play.svg');
      }
    }

    .context-notice {
      background: transparentize($black, 0.4);
      padding: rem-calc(25);
      text-align: center;
      width: 100%;
      z-index: 1;

      p {
        color: $white;
      }
    }
  }
}
