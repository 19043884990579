/**
 * Teaser-Slider
 * -------------------------
 */

.ce-teaser-slider {
  &__content {
    margin-bottom: rem-calc(80);
    text-align: center;

    .headline {
      margin: 0 auto;
      max-width: rem-calc(1000);
    }

    .rte {
      margin: rem-calc(10) auto 0;
      max-width: rem-calc(800);
    }
  }

  &__item {
    background: $white;
    margin-top: rem-calc(20);
    padding: rem-calc(20) rem-calc(20) rem-calc(60);
    position: relative;
    text-align: center;
    width: calc(100vw - #{rem-calc(100)});

    .figure {
      margin: rem-calc(-40) auto rem-calc(20);
      width: 50%;
    }

    .rte {
      margin-top: rem-calc(10);
    }
  }

  &__slide-number {
    color: $gray;
    font-size: rem-calc(16);
    left: rem-calc(10);
    line-height: rem-calc(26);
    position: absolute;
    top: rem-calc(10);

    span {
      font-size: rem-calc(26);
    }
  }

  &__link {
    margin-top: rem-calc(60);
    text-align: center;
  }

  .slider__item {
    @include animate(opacity);

    &:not(.slider__item--current) {
      opacity: 0.3;
    }
  }

  &--process {
    .ce-teaser-slider__item {
      border: rem-calc(1) solid $very-dark-gray;

      &:after {
        @include rem-size(30, 1);
        background: transparentize($very-dark-gray, 0.6);
        content: '';
        display: block;
        position: absolute;
        right: rem-calc(-31);
        top: 50%;
      }
    }

    .slider__item:last-child {
      .ce-teaser-slider__item:after {
        display: none;
      }
    }
  }

  &--wallpaper {
    .ce-teaser-slider__item {
      margin-top: 0;
      padding: rem-calc(50) 0;

      .figure {
        @include stretch-to-parent();
        background: no-repeat center/cover;
        margin: 0;
        width: 100%;
      }
    }

    .ce-teaser-slider__slide-number {
      z-index: 1;
    }

    .ce-teaser-slider__slide-content {
      background: transparentize($white, 0.1);
      padding: rem-calc(20);
      position: relative;
    }
  }

  @include media-breakpoint-up(md) {
    &__item {
      padding-bottom: rem-calc(80);
      width: 66.66667vw;
    }

    &__slide-number {
      span {
        font-size: rem-calc(58);
        line-height: rem-calc(64);
      }
    }

    &--wallpaper {
      .ce-teaser-slider__item {
        padding-bottom: rem-calc(84);
        padding-top: rem-calc(84);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__item {
      margin-top: rem-calc(40);
      padding-left: rem-calc(40);
      padding-right: rem-calc(40);
      padding-top: rem-calc(40);
      width: 41.66667vw;

      .figure {
        margin-bottom: rem-calc(40);
        margin-top: rem-calc(-80);
        width: 50%;
      }
    }

    &__slide-number {
      left: rem-calc(20);
    }

    &--wallpaper {
      .ce-teaser-slider__slide-content {
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);
      }
    }
  }
}
