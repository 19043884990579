/**
 * Info-Banner
 * -------------------------
 */

.ce-info-banner {
  background: $body-bg;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__icon {
    flex: 0 0 auto;
    padding: rem-calc(10) 0 rem-calc(6) rem-calc(20);
  }

  &__content {
    flex: 1 1 auto;
    padding: rem-calc(10) 0 rem-calc(6) rem-calc(10);
  }

  &__text {
    @include headline(sm);
    max-width: rem-calc(550);
  }

  &__link {
    @include tiny-text();
    font-weight: 700;
    line-height: rem-calc(20);
    margin-top: rem-calc(2);
    text-transform: uppercase;

    span {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__close {
    align-self: stretch;
    appearance: none;
    background: transparent url('#{$image-base-uri}icon-close-dark.svg') no-repeat center;
    background-size: rem-calc(16) rem-calc(16);
    border: none;
    flex: 0 0 auto;
    width: rem-calc(66);
  }

  @for $i from 1 through length($hover-colors) {
    &--color-variant-#{$i} {
      .ce-info-banner__inner {
        @include animate(background-color);
        background-color: nth($hover-colors, $i);
      }
    }
  }

  &--white-font {
    color: $white;

    .ce-info-banner__close {
      background-image: url('#{$image-base-uri}icon-close-light.svg');
    }
  }

  @include media-breakpoint-up(md) {
    &__icon,
    &__content {
      padding: rem-calc(12) 0 rem-calc(12) rem-calc(30);
    }

    &__close {
      width: rem-calc(76);
    }
  }
}

a.ce-info-banner {
  &--white-font {
    &:hover,
    &:focus {
      color: $white;
    }
  }

  @for $i from 1 through length($hover-colors) {
    &--color-variant-#{$i} {
      &:hover,
      &:focus {
        .ce-info-banner__inner {
          background-color: transparentize(nth($hover-colors, $i), 0.2);
        }
      }
    }
  }
}
