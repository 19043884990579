/**
 * Podcast list
 * -------------------------
 */

.ce-podcast-list {
  &__wrapper {
    margin: 0 auto;
    max-width: rem-calc(1050);

    > .headline {
      margin-bottom: rem-calc(40);
    }
  }

  &__elements {
    margin-bottom: rem-calc(-20);

    > * {
      margin: 0 0 rem-calc(20);
    }
  }

  &__show-more {
    padding-top: rem-calc(40);
    text-align: center;
  }

  @include media-breakpoint-up(lg) {
    &__wrapper > .headline {
        margin-bottom: rem-calc(80);
    }
  }
}
