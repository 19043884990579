/**
 * Layout
 * -------------------------
 */

.page-wrapper {
  overflow: hidden;
}

.container {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: rem-calc(2440);

  @include media-breakpoint-up(md) {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
  }

  @include media-breakpoint-up(lg) {
    padding-left: rem-calc(60);
    padding-right: rem-calc(60);
  }

  @include media-breakpoint-up(xl) {
    padding-left: rem-calc(120);
    padding-right: rem-calc(120);
  }
}

@each $direction in bottom, top {
  .spacing-#{$direction} {
    &--small {
      margin-#{$direction}: rem-calc(20);

      &-negative {
        margin-#{$direction}: rem-calc(-20);
      }
    }

    &--medium {
      margin-#{$direction}: rem-calc(80);

      &-negative {
        margin-#{$direction}: rem-calc(-80);
      }
    }

    &--large {
      margin-#{$direction}: rem-calc(80);

      &-negative {
        margin-#{$direction}: rem-calc(-80);
      }
    }

    @include media-breakpoint-up(md) {
      &--medium {
        margin-#{$direction}: rem-calc(80);

        &-negative {
          margin-#{$direction}: rem-calc(-80);
        }
      }

      &--large {
        margin-#{$direction}: rem-calc(100);

        &-negative {
          margin-#{$direction}: rem-calc(-100);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &--small {
        margin-#{$direction}: rem-calc(40);

        &-negative {
          margin-#{$direction}: rem-calc(-40);
        }
      }

      &--medium {
        margin-#{$direction}: rem-calc(80);

        &-negative {
          margin-#{$direction}: rem-calc(-80);
        }
      }

      &--large {
        margin-#{$direction}: rem-calc(160);

        &-negative {
          margin-#{$direction}: rem-calc(-160);
        }
      }
    }
  }

  .inner-spacing-#{$direction} {
    &--small {
      padding-#{$direction}: rem-calc(20);
    }

    &--medium {
      padding-#{$direction}: rem-calc(80);
    }

    &--large {
      padding-#{$direction}: rem-calc(80);
    }

    @include media-breakpoint-up(md) {
      &--medium {
        padding-#{$direction}: rem-calc(80);
      }

      &--large {
        padding-#{$direction}: rem-calc(100);
      }
    }

    @include media-breakpoint-up(lg) {
      &--small {
        padding-#{$direction}: rem-calc(40);
      }

      &--medium {
        padding-#{$direction}: rem-calc(80);
      }

      &--large {
        padding-#{$direction}: rem-calc(160);
      }
    }
  }
}
