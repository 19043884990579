/**
 * Text
 * -------------------------
 */

.ce-text {
  .headline {
    margin-bottom: rem-calc(20);
  }

  @include media-breakpoint-down(sm) {
    &__sub-elements {
      margin-top: rem-calc(40);

      &--above {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    @include make-row();

    &__wrapper {
      @include make-col-ready();
      @include make-col(10);
      @include make-col-offset(1);

      &--full-width {
        @include make-col(12);
        @include make-col-offset(0);
      }
    }

    &__content {
      @include clearfix();
      margin: 0 auto;
      max-width: rem-calc(780);
    }

    &__sub-elements {
      margin-bottom: $grid-gutter-width;
      width: calc(50% - #{$grid-gutter-width / 2});

      &--left {
        float: left;
        margin-right: $grid-gutter-width;
      }

      &--right {
        float: right;
        margin-left: $grid-gutter-width;
      }

      &--below {
        display: none;
      }
    }
  }
}
