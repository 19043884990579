/**
 * Slider
 * -------------------------
 */

.slider {
  position: relative;

  .tns-nav {
    bottom: rem-calc(20);
    left: rem-calc(20);
    justify-content: center;
    position: absolute;
    right: rem-calc(20);
    top: auto;
  }

  &__info {
    @include copy-text();
    margin-bottom: rem-calc(10);
  }

  &__controls {
    outline: none;

    .button {
      position: absolute;
      z-index: 1;

      &--next,
      &--prev {
        &:after {
          @include rem-size(50);
        }
      }

      &[disabled] {
        opacity: 0;
      }
    }

    &--static {
      height: rem-calc(40);
      margin: rem-calc(40) auto;
      position: relative;
      width: rem-calc(120);

      .button {
        top: 0 !important;

        &:first-child {
          left: 0 !important;
        }

        &:last-child {
          right: 0 !important;
        }
      }
    }

    &--small {
      .button {
        &--next,
        &--prev {
          &:after {
            @include rem-size(40);
          }
        }
      }
    }
  }

  &__items {
    align-items: center;
    display: flex;

    &--align-top {
      align-items: flex-start;
    }

    &--stretch {
      align-items: stretch;
    }
  }

  &__item {
    flex: 0 0 auto;
  }

  &__progress {
    background: $gray;
    margin-top: rem-calc(20);

    &-bar {
      @include animate(width);
      background: nth($hover-colors, 10);
      height: rem-calc(10);
    }
  }

  @include media-breakpoint-down(sm) {
    &__info {
      line-height: rem-calc(40);
    }

    &__controls {
      .button {
        top: 0;

        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
        }
      }

      &--bottom {
        .button {
          top: auto;
          bottom: 0;
        }
      }

      &--below {
        .button {
          top: auto;
          bottom: rem-calc(-55);
        }
      }

      &--inside {
        .button {
          top: calc(50% - #{rem-calc(25)});

          &:first-child {
            left: rem-calc(20);
          }

          &:last-child {
            right: rem-calc(20);
          }
        }
      }

      &--bottom-inside {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__controls {
      .button {
        top: calc(50% - #{rem-calc(25)});

        &:first-child {
          left: rem-calc(-50);
        }

        &:last-child {
          right: rem-calc(-50);
        }
      }

      &--wide {
        .button {
          &:first-child {
            left: rem-calc(-80);
          }

          &:last-child {
            right: rem-calc(-80);
          }
        }
      }

      &--inside {
        .button {
          &:first-child {
            left: rem-calc(40);
          }

          &:last-child {
            right: rem-calc(40);
          }
        }
      }

      &--bottom-inside {
        .button {
          bottom: rem-calc(20);
          top: auto;

          &:first-child {
            left: rem-calc(20);
          }

          &:last-child {
            right: rem-calc(20);
          }
        }
      }

      &--only-on-hover {
        .button {
          opacity: 0;

          @at-root .slider:hover &:not([disabled]) {
            opacity: 1;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__controls {
      &--wide {
        .button {
          &:first-child {
            left: rem-calc(-135);
          }

          &:last-child {
            right: rem-calc(-135);
          }
        }
      }

      &--inside {
        .button {
          &:first-child {
            left: rem-calc(60);
          }

          &:last-child {
            right: rem-calc(60);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__controls--inside {
      .button {
        &:first-child {
          left: rem-calc(120);
        }

        &:last-child {
          right: rem-calc(120);
        }
      }
    }
  }
}
