/**
 * Global
 * -------------------------
 */

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  position: relative;

  &.disable-scroll {
    overflow: hidden;
  }
}

ul.unstyled,
nav ul {
  @include unstyled-list();
}

blockquote,
figure {
  margin: 0;
}

a {
  cursor: pointer;

  // Fix for auto-detected phone-numbers on iOS
  &[href^=tel] {
    color: inherit;
  }
}

.link {
  @include animate(color);

  &--back {
    background: url('#{$image-base-uri}icon-arrow-left.svg') no-repeat left center;
    padding-left: rem-calc(16);
  }

  &--small {
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
  }

  @for $i from 1 through length($hover-colors) {
    &--color-variant-#{$i} {
      &:hover,
      &:focus {
        color: nth($hover-colors, $i);
      }
    }
  }
}

.figure {
  margin: 0;

  img {
    @include img-fluid();
  }

  figcaption {
    @include copy-text();
    margin: rem-calc(10) 0 0;
    text-align: center;
  }

  &--align-left {
    figcaption {
      text-align: left;
    }
  }

  &--colored-background {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;

    img {
      width: auto;
      height: auto;
      margin: 0 5% 10% 0;
      max-height: 80%;
      max-width: 80%;
      position: relative;
    }

    &:before {
      background: nth($hover-colors, 6);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 35%;
    }

    @for $i from 1 through length($hover-colors) {
      &.figure--color-variant-#{$i}:before {
        background: nth($hover-colors, $i);
      }
    }
  }

  &--rounded img {
    border-radius: 50%;
  }
}

.video-wrapper {
  overflow: hidden;
  position: relative;

  &::before {
    display: block;
    content: '';
    padding-top: percentage(9/16);
  }

  iframe,
  video,
  [data-type="placeholder"] {
    @include stretch-to-parent();
    height: 100%;
    width: 100%;
  }

  [data-type="placeholder"] {
    max-width: 100% !important;
    height: 100% !important;
  }

  &--4by3::before {
    padding-top: percentage(3/4);
  }
}

.spam-protection {
  &--at:before {
    content: '@';
  }

  &--dot:before {
    content: '.';
  }

  &__inner {
    display: inline-block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

.vcard {
  background: $white;

  &__image {
    img {
      @include img-fluid();
    }
  }

  &__content {
    padding: rem-calc(20);
  }

  &__name {
    @include headline(sm);
  }

  @each $type in email, phone {
    &__#{$type} {
      background: url('#{$image-base-uri}icon-#{$type}.svg') no-repeat 0 rem-calc(3);
      margin-top: rem-calc(18);
      padding-left: rem-calc(28);
    }
  }

  &__email + &__phone {
    margin-top: rem-calc(8);
  }
}

.quote {
  background: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem-calc(40) rem-calc(20);
  text-align: center;

  &__quote {
    @include headline(sm);

    &:before {
      @include rem-size(60, 47);
      background: url('#{$image-base-uri}icon-quote-alt.svg') no-repeat center/contain;
      content: '';
      display: block;
      margin: 0 auto rem-calc(20);
    }
  }

  &__source {
    @include copy-text();
    margin-top: rem-calc(10);
  }
}

.record-list {
  @include unstyled-list();

  &__item {
    background: $white;

    &:not(:last-child) {
      margin-bottom: rem-calc(10);
    }

    a {
      display: flex;

      &:hover,
      &:focus {
        .record-list__item-content:after {
          width: 100%;
        }
      }
    }

    &-image {
      background: no-repeat center;
      background-size: cover;
      flex: 0 0 auto;
      margin: 0;
      min-height: rem-calc(120);
      width: rem-calc(120);
    }

    &-content {
      flex: 1 1 auto;
      padding: rem-calc(10);
      position: relative;

      .overline {
        margin-bottom: rem-calc(4);
      }

      &:after {
        @include animate(width);
        bottom: 0;
        content: '';
        height: rem-calc(2);
        left: 0;
        position: absolute;
        width: 0;
      }
    }
  }

  @for $i from 1 through length($hover-colors) {
    &--color-variant-#{$i} .record-list__item-content:after {
      background: nth($hover-colors, $i);
    }
  }
}

.messages {
  color: $white;
  margin: 0 0 rem-calc(30);
  padding: rem-calc(10) rem-calc(20);

  p {
    margin: 0;
  }

  &--error {
    background: $error;
  }

  &--success {
    background: $success;
  }
}

.typo3-messages {
  @include unstyled-list();

  .alert {
    @extend .messages;
  }

  .alert-danger {
    @extend .messages--error;
  }

  .alert-success {
    @extend .messages--success;
  }
}

.confetti {
  @include stretch-to-parent();
  overflow: hidden;
  z-index: -1;

  &__layer {
    @include stretch-to-parent();
    background: top center;

    @for $layer from 1 to 3 {
      &--layer-#{$layer} {
        background-image: url('#{$image-base-uri}bg-confetti-layer-#{$layer}.svg');
      }
    }
  }
}

.position-relative {
  position: relative;
}

.only-mobile {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.only-desktop {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
