/**
 * Fonts
 * -------------------------
 */

@font-face {
    font-family: 'DIN OT';
    src: url('#{$font-base-uri}dinot.woff2') format('woff2'),
         url('#{$font-base-uri}dinot.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'DIN OT';
    src: url('#{$font-base-uri}dinot-bold.woff2') format('woff2'),
         url('#{$font-base-uri}dinot-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
