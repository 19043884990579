/**
 * Image with text teaser
 * -------------------------
 */

.ce-image-text-teaser {
  background: $white;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  &--white-font {
    background: $black;
    color: $white;
  }

  &--full-height {
    height: 100%;
  }

  &--background-image {
    min-height: rem-calc(600);

    .ce-image-text-teaser__content .rte {
      max-width: rem-calc(350);
    }
  }

  &__image {
    flex: 0 0 auto;

    img {
      @include img-fluid();
    }
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: rem-calc(20);

    .headline {
      width: 100%;
    }

    .rte {
      flex: 1 0 auto;
      width: 100%;
    }

    .headline + .rte {
      margin-top: rem-calc(5);
    }

    .headline + .button,
    .rte + .button {
      margin-top: rem-calc(40);
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      .headline + .rte {
        margin-top: rem-calc(10);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      max-width: rem-calc(880);
      padding: rem-calc(40);
    }
  }
}
