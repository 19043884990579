/**
 * Vendor
 * -------------------------
 */

//
// Bootstrap
//
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";
@import "~bootstrap/scss/mixins/deprecate";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/image";
@import "~bootstrap/scss/mixins/hover";
@import "~bootstrap/scss/mixins/screen-reader";
@import "~bootstrap/scss/mixins/transition";
@import "~bootstrap/scss/mixins/clearfix";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/mixins/grid-framework";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/utilities/screenreaders";

//
// Magnific Popup
//
@import "~magnific-popup/src/css/main";

.mfp-close {
  font-size: rem-calc(40);

  @include media-breakpoint-up(lg) {
    right: rem-calc(20);
    top: rem-calc(20);

    &:active {
      top: rem-calc(20);
    }
  }
}

.mfp-close-btn-in .mfp-close {
  color: $black;
}

//
// Tiny-Slider
//
@import "~tiny-slider/src/tiny-slider";

.tns {
  &-outer {
    position: relative;
  }

  &-nav {
    display: flex;
    left: rem-calc(20);
    position: absolute;
    top: rem-calc(20);
    z-index: 10;

    button {
      @include animate(opacity);
      @include rem-size(10);
      background: $white;
      border: none;
      box-shadow: $box-shadow;
      display: block;
      outline: none;
      opacity: 0.42;
      padding: 0;

      &:not(:first-child) {
        margin-left: rem-calc(12.5);
      }

      &.tns-nav-active {
        opacity: 1;
      }
    }

    @include media-breakpoint-up(lg) {
      left: rem-calc(40);
      top: rem-calc(40);
    }
  }
}

//
// Pikaday
//
$pd-day-hover-bg: #9bbd2b;
$pd-day-today-color: #4486fe;
$pd-day-selected-bg: #a90e0a;
$pd-day-selected-shadow: darken(#a90e0a, 10%);

@import "~pikaday/scss/pikaday";

//
// AOS
//
@import "~aos/src/sass/aos";

[data-aos][data-aos="speaker-background"] {
  @include animate(width);
  width: 100%;

  &.aos-animate {
    width: 30%;
  }
}
