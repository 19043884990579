/**
 * Reference slider
 * -------------------------
 */

.ce-reference-slider {
  &__desktop-version {
    display: none;
  }

  &__content {
    margin: 0 auto rem-calc(40);
    max-width: rem-calc(785);
    text-align: center;

    .headline:not(:last-child) {
      margin-bottom: rem-calc(20);
    }

    .button {
      margin-top: rem-calc(20);
    }
  }

  &__item {
    padding-bottom: rem-calc(10);

    &-overlay {
      @include animate(top);
      @include stretch-to-parent();
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: rem-calc(40) rem-calc(60);
      text-align: center;

      .overline {
        margin-bottom: rem-calc(12);
      }
    }

    &--image {
      .ce-reference-slider__card {
        background: no-repeat center/cover;
        display: block;
        height: 0;
        padding-bottom: 150%;
        position: relative;

        .button {
          bottom: rem-calc(-10);
          position: absolute;
          right: calc(50% - #{rem-calc(20)});
        }

        &:hover,
        &:focus {
          .ce-reference-slider__item-overlay {
            top: -40%;
          }
        }
      }
    }

    &--text {
      .headline {
        margin-bottom: rem-calc(10);
      }

      .button {
        margin-top: rem-calc(40);
      }
    }
  }

  .tns-ovh {
    overflow: visible;
  }

  &--white-background {
    background: $white;
  }

  @include media-breakpoint-down(sm) {
    &__item--image .ce-reference-slider__card .button--arrow {
      @include rem-size(40);

      &:after {
        background-size: rem-calc(50) rem-calc(50);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__mobile-version {
      display: none;
    }

    &__desktop-version {
      display: block;
    }

    &__item--image .ce-reference-slider__card .button {
      right: rem-calc(-10);
    }
  }
}
